<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP WORK ORDER LIST

type    : view

uses    : header-list
          header-view
          main-container
          table-status

route   : /workorders (default route)
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-work-order-list">
    <header-list title="Work Orders" />
    <main-container>
      <div>
        <v-card>
          <v-card-title>
            <v-btn
              height="28"
              class="button-primary"
              @click="filterdialog = true">
              <span>Filter</span>
              <v-icon right small> mdi-filter </v-icon>
            </v-btn>
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="QuickSearch"
              single-line
              hide-details />
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="busy ? [] : filteredWorkOrders"
            class="elevation-1"
            sort-by="id"
            sort-desc
            :search="search"
            :loading="busy">
            <template v-slot:item="props">
              <tr @click="viewWorkOrder(props.item)">
                <td>
                  {{
                    props.item.id
                      ? 'WO-' + props.item.id.toString().padStart(5, '0')
                      : 'Unavailable'
                  }}
                </td>
                <td>{{ props.item.name || 'Unassigned' }}</td>
                <td>{{ props.item.client_name || 'Not Available' }}</td>
                <td>
                  <div class="date-container">
                    {{
                      props.item.scheduled_date
                        ? displayDateFormat(
                            props.item.scheduled_date,
                            dateFormat
                          )
                        : '--'
                    }}
                    <v-icon
                      v-if="isOverdue(props.item)"
                      class="overdue-icon"
                      title="Overdue">
                      mdi-alert
                    </v-icon>
                  </div>
                </td>
                <td v-show="allowWorkOrderAmount">
                  {{
                    formatCurrency(props.item.subtotal, currencySymbol) ||
                    'Unavailable'
                  }}
                </td>

                <td class="text-center">
                  {{ formatInvoiceId(props.item.invoice_number) }}
                </td>
                <td class="text-center">
                  <table-status
                    :displayed-status="props.item.status"
                    :status="'workorder ' + props.item.status" />
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              There are no current work orders, approve an estimate to create
              one.
            </template>
          </v-data-table>
        </v-card>
      </div>

      <v-dialog v-model="filterdialog" persistent max-width="600px">
        <v-card color="grey lighten-4" min-width="350px" flat>
          <header-view title="Filter Work Orders" dialog />
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="client_name"
                    label="Client Name Contains"
                    hint=""
                    required />
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px">
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="date_scheduled_greater"
                        label="Date Scheduled Greater than"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on" />
                    </template>
                    <v-date-picker
                      v-model="date_scheduled_greater"
                      @input="menu1 = false" />
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px">
                    <template v-slot:activator="{on}">
                      <v-text-field
                        v-model="date_scheduled_less"
                        label="Date Scheduled Less than"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-on="on" />
                    </template>
                    <v-date-picker
                      v-model="date_scheduled_less"
                      @input="menu2 = false" />
                  </v-menu>
                </v-flex>
                <v-flex xs12>
                  <div id="workorder-status-filter">
                    <v-select
                      v-model="status"
                      :items="filterItems"
                      filled
                      label="Status" />
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text class="button-primary" @click="applyFilter()">
              Apply
            </v-btn>
            <v-btn text class="button-secondary" @click="clearFilter()">
              Clear
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </main-container>
  </div>
</template>

<script>
  // components
  import HeaderList from '@/components/header-list';
  import MainContainer from '@/components/main-container';
  import TableStatus from '@/components/table-status';
  import HeaderView from '@/components/header-view';

  // mixins
  import Localization from '@/mixins/localization';
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';

  // services
  import Jobs from '@/services/Jobs.service.js';
  import Clients from '@/services/Clients.service.js';
  import Invoices from '@/services/Invoices.service.js';

  export default {
    name: 'AppWorkOrderList',
    components: {
      'header-view': HeaderView,
      'header-list': HeaderList,
      'main-container': MainContainer,
      'table-status': TableStatus,
    },
    mixins: [dateTimeHelpers, Localization],
    props: {},
    data() {
      return {
        menu1: false,
        menu2: false,
        filterdialog: false,
        client_name: null,
        date_scheduled_greater: null,
        date_scheduled_less: null,
        status: null,
        tenant_uuid: this.$auth.tenantProfile.uuid,
        filterItems: [
          {
            text: 'Show All',
            value: '',
          },
          {
            text: 'Not Scheduled',
            value: 'Not Scheduled',
          },
          {
            text: 'Scheduled',
            value: 'Scheduled',
          },
          {
            text: 'In Progress',
            value: 'In Progress',
          },
          {
            text: 'Completed',
            value: 'Completed',
          },
          {
            text: 'Invoiced',
            value: 'Invoiced',
          },
        ],
        search: '',
        workorders: [],
        allowWorkOrderAmount: false,
        busy: false,
        selected: [],
        snackbar: false,
        invoiceDialog: false,
        notifyDialog: false,
        notifyDialogText: '',
        selected_worktasks: [],
        createDialog: false,
        viewDialog: false,
        editDialog: false,
        moveDialog: false,
        newWorkOrder: {},
        scheduled_date: '',

        headers: [
          {
            text: 'Workorder ID',
            value: 'id',
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Client Name',
            value: 'client_name',
          },
          {
            text: 'Scheduled Date',
            value: 'scheduled_date',
          },
          {
            text: 'Amount',
            value: 'amount',
          },
          {
            text: 'Invoice ID',
            value: 'invoice',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        worktaskheaders: [
          {
            text: 'Category',
            value: 'workcategory',
          },
          {
            text: 'Type',
            value: 'worktype',
          },
          {
            text: 'Status',
            value: 'status',
          },
        ],
      };
    },
    computed: {
      dateFormat() {
        return this.$auth.tenantProfile.date_format;
      },
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      filteredWorkOrders() {
        return this.workorders.filter(
          function (workorder) {
            let pass = true;
            if (
              this.client_name &&
              !workorder.client_name
                .toLowerCase()
                .includes(this.client_name.toLowerCase())
            ) {
              pass = false;
            }
            if (
              this.date_scheduled_greater &&
              workorder.scheduled_date < this.date_scheduled_greater
            ) {
              pass = false;
            }
            if (
              this.date_scheduled_less &&
              workorder.scheduled_date > this.date_scheduled_less
            ) {
              pass = false;
            }
            if (this.status && workorder.status != this.status) {
              pass = false;
            }
            return pass;
          }.bind(this)
        );
      },
    },
    async created() {
      this.allowWorkOrderAmount = this.$auth.isAllowed('workorders', 'amount');
      if (!this.allowWorkOrderAmount) {
        this.headers.splice(4, 1); // remove amount header
      }

      this.busy = true;

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get all workorders
      this.workorders = await Jobs.getWorkOrders(
        {
          client_name: this.client_name,
          date_scheduled_greater: this.date_scheduled_greater,
          date_scheduled_less: this.date_scheduled_less,
          status: this.status || '',
          tenant_uuid: this.tenant_uuid,
        },
        accessToken
      );

      if (this.workorders && this.workorders.length > 0) {
        // get clients
        await this.loadClients(accessToken);

        // get scheduled dates
        await this.loadScheduledDates(accessToken);

        // get subtotals based on added up worktasks
        await this.loadWorkOrderTotals(accessToken);

        // get invoice ids
        await this.loadInvoiceIDs(accessToken);

        this.busy = false;
      } else {
        console.log('could not get any workorders...');
        this.busy = false;
      }
    },
    methods: {
      async loadScheduledDates(accessToken) {
        // get each workorders' scheduled date
        this.workorders = await Jobs.getScheduledDateForWorkOrders(
          this.workorders,
          accessToken
        );
      },
      async loadClients(accessToken) {
        // get client uuids from all the work orders
        var client_uuids = this.workorders
          .filter((a) => (a.client_uuid ? true : false))
          .map((a) => a.client_uuid);

        // get all clients matching the uuids and update each workorder client name
        if (
          client_uuids &&
          client_uuids.length > 0 &&
          this.workorders.length > 0
        ) {
          // set params
          let client_params = {
            tenant_uuid: this.tenant_uuid,
            client_uuids: client_uuids,
          };

          var clients = await Clients.getClients(client_params, accessToken);

          if (clients && clients.length > 0) {
            for (let client of clients) {
              this.workorders.filter((a) => {
                if (a.client_uuid == client.uuid) {
                  a.client_name = client.client_name;
                }
              });
            }
          }
        }
      },
      async loadInvoiceIDs(accessToken) {
        // get invoice uuids from all the work orders
        var uuids = this.workorders
          .filter((a) => (a.invoice_uuid ? true : false))
          .map((a) => a.invoice_uuid);

        // get all clients matching the uuids and update each workorder client name
        if (uuids && uuids.length > 0) {
          var invoices = await Invoices.getInvoicesByIDArray(
            this.tenant_uuid,
            {
              invoice_uuids: uuids,
            },
            accessToken
          );

          if (invoices && invoices.length > 0) {
            for (let invoice of invoices) {
              let object = this.workorders.find(
                (a) => a.invoice_uuid === invoice.uuid
              );
              if (object) {
                object.invoice_number = invoice.number;
              }
            }
          }
        }
      },
      async loadWorkOrderTotals(accessToken) {
        // get uuids from all the work orders
        var uuids = this.workorders.map((a) => a.uuid);

        // get all work tasks matching the workorder uuid and update each workorder total
        if (uuids && uuids.length > 0 && this.workorders.length > 0) {
          // set params
          let params = {
            tenant_uuid: this.tenant_uuid,
            workorder_uuids: uuids,
          };

          var tasks = await Jobs.getWorkTasks(params, accessToken);

          if (tasks && tasks.length > 0) {
            for (let task of tasks) {
              let object = this.workorders.find(
                (a) => a.uuid === task.workorder_uuid
              );
              if (object) {
                object.subtotal = object.subtotal || 0;
                if (task.subtotal) {
                  object.subtotal += parseInt(task.subtotal);
                }
              }
            }
          }
        }
      },
      formatInvoiceId(id) {
        return id ? 'INV-' + id.toString().padStart(5, '0') : '-';
      },
      isOverdue(workorder) {
        return (
          this.isDateBeforeToday(workorder.scheduled_date) &&
          workorder.status.toLowerCase() == 'scheduled'
        );
      },
      viewWorkOrder: function (workorder) {
        this.$router.push({
          name: 'WorkOrderView',
          params: {
            uuid: workorder.uuid,
            wo: workorder,
          },
        });
      },
      clearFilter: function () {
        this.client_name = null;
        this.status = null;
        this.date_scheduled_greater = null;
        this.date_scheduled_less = null;
        this.filterdialog = false;
        this.filterWorkOrders();
      },
      applyFilter: function () {
        this.filterdialog = false;
        this.filterWorkOrders();
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped>
  #filter-select {
    height: 60px;
    width: 100px;
    margin: auto;
  }

  .date-container {
    width: 110px;
  }

  .overdue-icon {
    float: right;
    font-size: 21px !important;
  }
</style>
